<template>
	<div class="layout-footer p-d-flex p-ai-center p-p-1 p-shadow-2 ">
		<span class="p-ml-auto p-mr-2 arenacxFooterLink" style="" @click="browseTo('acx')" >ArenaCX &copy;2021</span>
        <Button icon="pi pi-linkedin fs-large" class="p-button-rounded p-button-text p-button-plain" @click="browseTo('li')" >
			<img src="assets/layout/images/linkedin_icon.svg"  style="width: 24px; height: 24px" />
		</Button>
        <Button icon="pi pi-facebook fs-large" class="p-button-rounded p-button-text p-button-plain" @click="browseTo('fb')" ></Button>
        <Button icon="pi pi-linkedin fs-large" class="p-button-rounded p-button-text p-button-plain" @click="browseTo('li')" ></Button>
    </div>
</template>

<script>
	export default {
		name: "AppFooter",
		props: {
			layoutMode: {
				type: String,
				default: 'light'
			}
		},
		methods: {
			browseTo: function(code) {
				
				switch(code) {
					case 'fb':
						window.open('https://www.facebook.com/arenacxofficial', '_blank');
						break;
					case 'acx':
						window.open('https://www.arenacx.com', '_blank');
						break;
					case 'li':
						window.open('https://www.linkedin.com/company/64690812/', '_blank');
						break;
				}
			}
		},
		computed: {
			isRTL() {
				return this.$appState.RTL;
			}
		}
	}
</script>

<style scoped>
	.arenacxFooterLink {
		cursor:pointer;
		font-weight: 500;
		color: #336b65;
		margin-right: 31px;
		display: inline-block;
		font-size: 85%;
	}
	.arenacxFooterLink:hover {
		
		color: 	#13b196;
		
	}

</style>